import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItem } from '../ListItem/ListItem';
import { IconInfoCircle } from '../Icon';
import { TooltipWithModal } from '../Tooltip';
import { Paragraph } from '../Typography';
import styles from './InfoBlock.module.scss';
import { ButtonLink } from '../ButtonLink';
import React from 'react';
export const InfoBlock = ({ heading, body, tooltip, icon, buttonProps }) => {
    const hasIcon = !!icon;
    const Icon = icon || IconInfoCircle;
    return (_jsxs("div", { className: styles['info-block'], children: [tooltip ? (_jsx(TooltipWithModal, { tooltipProps: tooltip, children: _jsx(Icon, { color: "strong" }) })) : (hasIcon && _jsx(Icon, { color: "strong" })), _jsxs("div", { className: styles['content-row'], children: [_jsx(ListItem, { heading: heading, children: _jsx(Paragraph, { children: body }) }), buttonProps && (_jsx("span", { style: {
                            display: buttonProps.length > 1 ? 'flex' : 'inline-block',
                            gap: buttonProps.length > 1 ? 8 : undefined,
                            alignItems: 'center',
                        }, children: buttonProps.map((button, index) => (_jsxs(React.Fragment, { children: [_jsx(ButtonLink, { size: "standard", variant: "secondary", onClick: button.onClick, text: button.text }), index < buttonProps.length - 1 && _jsx("span", { children: "\u2022" })] }, index))) }))] })] }));
};
